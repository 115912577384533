import React from 'react';
import styled from 'styled-components';
import LoginImage from './LoginImage';
import Button from '../../../components/Button';
import { FiArrowLeft, FiSend } from 'react-icons/fi';
import { Body, Caption1, LargeTitle } from '../../../styles/typography';
import { useFormik } from 'formik';
import Input from '../../../components/Input';
import { navigate } from 'gatsby';
import { routes } from '../../../navigator/routes';
import {
  InitiateForgotPasswordRequest,
  selectForgotPasswordResponse,
  selectStatus,
} from '../../../app/reducers/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
const Container = styled.div`
  max-width: 480px;
  margin-left: 50vw;
  padding: 32px;
  min-height: -webkit-fill-available;
  display: grid;
  gap: 32px;

  @media screen and (max-width: 800px) {
    margin-left: 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledHeader = styled.div`
  display: grid;
  gap: 12px;
`;
const StyledButton = styled(Button)`
  max-width: fit-content;
`;

const StyledForm = styled.form`
  display: grid;
  gap: 32px;
`;
const ErrorMessage = styled(Caption1)`
  color: rgb(${(props) => props.theme.colors.error400});
  text-align: center;
  height: 1em;
`;
const Back = () => {
  return (
    <StyledButton type="tertiary" to="/auth/login">
      <FiArrowLeft />
      Back
    </StyledButton>
  );
};
const Header = () => {
  return (
    <StyledHeader>
      <LargeTitle>Password Recovery</LargeTitle>
      <Body>
        If you’ve lost your password or wish to reset it, enter the email
        address associated with your account.
      </Body>
    </StyledHeader>
  );
};

const EmailForm = () => {
  const Response = useSelector(selectForgotPasswordResponse);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      try {
        dispatch(InitiateForgotPasswordRequest(values.email))
          .unwrap()
          .catch((err) => err)
          .finally((res) => {
            console.clear();
            navigate(routes.recoveryEmailSent.path, {
              state: { email: values.email },
            });
          });
      } catch (error) {
        //
      }
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Email is required';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = 'Invalid email address';
      }
      return errors;
    },
  });
  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <Input
        label="Email"
        placeholder="Enter your email..."
        name={'email'}
        errorMessage={formik.errors.email}
        value={formik.values.email}
        onBlur={formik.handleBlur}
        onValueChange={formik.handleChange}
        errorCondition={formik.touched.email && formik.errors.email}
      />
      <Button
        type="primary"
        disabled={!formik.isValid}
        data-testid=""
        loading={status === 'loading'}
      >
        Send email <FiSend />
      </Button>
    </StyledForm>
  );
};
const PasswordRecovery = () => {
  return (
    <>
      <LoginImage />
      <Wrapper>
        <Container>
          <Back />
          <Header />
          <EmailForm />
          <ErrorMessage>{Response.message}</ErrorMessage>
        </Container>
      </Wrapper>
    </>
  );
};
export default PasswordRecovery;
