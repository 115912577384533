import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { LargeTitle, Subhead } from '../../../styles/typography';
import { FiArrowRight } from 'react-icons/fi';
import { name, SignUpValidate } from '../../../util/functions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectResponse,
  updateUserDetails,
  selectStatus,
} from '../../../app/reducers/UserSlice';
import { navigate } from 'gatsby';
import { routeswithoutNavbar } from '../../../navigator/routes';
import { SpinnerCircular } from 'spinners-react';
import theme from '../../../styles/colors';
import { useLocation } from '@reach/router';

const ImageWrapper = styled.div`
  display: inline;
  position: fixed;
  width: 50vw;
  height: 100vh;
  height: -webkit-fill-available;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const FormWrapper = styled.div`
  display: inline-grid;
  justify-content: center;
  align-items: center;
  width: 50vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  justify-content: stretch;
  justify-items: center;

  @media screen and (max-width: 800px) {
    margin-left: 0;
    width: 100vw;
  }
`;

const FormContainer = styled.div`
  padding: 32px 42px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 500px;
  justify-content: stretch;
`;
const SignUpButton = styled(Button)`
  :disabled {
    background: rgba(11, 12, 15, 0.5);
    border: none;
  }
`;
const UserDetail = () => {
  const response = useSelector(selectResponse);
  const location=useLocation();
  const [firstName, setFirstName] = useState(name(location.state.data.fullName.split(' ')).firstName );
  const [lastName, setLastName] = useState(name(location.state.data.fullName.split(' ')).lastName);
  const [birthYear,setBirthYear] = useState(location.state.data.birthYear);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [birthYearError,setBirthYearError]=useState('');
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const Update = () => {
    const obj = {
      data: {
        firstName: firstName,
        lastName: lastName,
        email: localStorage.getItem('email'),
        birthYear:birthYear
      },
    };
    dispatch(updateUserDetails(obj));
  };
  useEffect(() => {
    if (response === 200) {
      navigate(routeswithoutNavbar.onboarding.path);
    }
  }, [response]);

  const theme = useTheme();

  return (
    <div>
      <FormWrapper>
        <FormContainer>
          <LargeTitle>Sign up</LargeTitle>
          <Input
            type="text"
            label="First name"
            placeholder="William"
            value={firstName}
            name="firstName"
            onValueChange={(event) => {
              setFirstName(event.target.value);
              setFirstNameError(
                SignUpValidate({ firstName: event.target.value })
              );
            }}
            errorCondition={firstNameError.firstName}
            errorMessage={firstNameError.firstName}
          />
          <Input
            type="text"
            name="lastName"
            label="Last name"
            placeholder="Shakespeare"
            value={lastName}
            onValueChange={(event) => {
              setLastName(event.target.value);
              setLastNameError(
                SignUpValidate({ lastName: event.target.value })
              );
            }}
            errorCondition={lastNameError.lastName}
            errorMessage={lastNameError.lastName}
          />
          <Input
            label="Birth Year"
            placeholder="1564"
            name={'birthYear'}
            type={'birthYear'}
            value={birthYear}
            onValueChange={(event) => {
              setBirthYear(event.target.value);
              setBirthYearError(
                SignUpValidate({ birthYear: event.target.value })
              );
            }}
            errorCondition={birthYearError.birthYear}
            errorMessage={birthYearError.birthYear}
          />
          <SignUpButton
            type="primary"
            onButtonClick={Update}
            disabled={!firstName || !lastName ||!birthYear}
          >
            {status === 'loading' ? (
              <SpinnerCircular
                size={20}
                thickness={200}
                speed={100}
                color={`rgba(${theme.colors.neutral200}, 1)`}
                secondaryColor={`rgba(${theme.colors.neutral100}, 1)`}
                style={{ justifySelf: 'center' }}
              />
            ) : (
              <Subhead>Sign Up</Subhead>
            )}
            <FiArrowRight />
          </SignUpButton>
        </FormContainer>
      </FormWrapper>
      <ImageWrapper>
        <StaticImage
          // eslint-disable-next-line max-len
          src="https://images.unsplash.com/photo-1517971129774-8a2b38fa128e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=988&q=80"
          placeholder="blurred"
          loading="eager"
          alt={'Sign Up Image'}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
      </ImageWrapper>
    </div>
  );
};
export default UserDetail;
