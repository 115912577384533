import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@gatsbyjs/reach-router';
import styled from 'styled-components';
import {
  ResetPasswordRequest,
  selectForgotPasswordResponse,
  selectStatus,
} from '../../../app/reducers/UserSlice';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { Body, Caption1, LargeTitle } from '../../../styles/typography';
import { passwordValidate } from '../../../util/functions';
import LoginImage from './LoginImage';

const Container = styled.div`
  max-width: 480px;
  margin-left: 50vw;
  padding: 32px;
  min-height: -webkit-fill-available;
  display: grid;
  gap: 32px;

  @media screen and (max-width: 800px) {
    margin-left: 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledHeader = styled.div`
  display: grid;
  gap: 12px;
`;
const PasswordDetailContainer = styled.div`
  width: 100%;
  ul {
    list-style: disc;
    padding-left: 35px;
  }
`;
const StyledBody = styled(Body)`
  > span {
    font-weight: bold;
  }
`;
const Header = () => {
  return (
    <StyledHeader>
      <LargeTitle>Reset your password</LargeTitle>
      <StyledBody>
        Enter your new password.
        <br />
      </StyledBody>
      <PasswordDetailContainer>
        <Body>When creating your password, please include:</Body>
        <ul>
          <li>
            <Body>a lowercase letter</Body>
          </li>
          <li>
            <Body>an uppercase letter</Body>
          </li>
          <li>
            <Body>a special character (!@#$%&*)</Body>
          </li>
          <li>
            <Body>a number</Body>
          </li>
          <li>
            <Body>at least 8 characters</Body>
          </li>
        </ul>
      </PasswordDetailContainer>
    </StyledHeader>
  );
};

const StyledForm = styled.form`
  display: grid;
  gap: 32px;
`;
const ErrorMessage = styled(Caption1)`
  color: rgb(${(props) => props.theme.colors.error400});
  text-align: center;
  height: 1em;
  padding-bottom: 10px;
`;
const InputGroup = styled.div`
  display: grid;
  gap: 24px;
`;
const ResetForm = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitUrl = pathname.split('/').filter(Boolean);
  const [auth, resetPassword, b64] = splitUrl;
  if (!b64) {
    navigate('/auth/login');
  }
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const ResetPasswordResponse = useSelector(selectForgotPasswordResponse);
  const asciiString = atob(b64); // sample@email.com:<epoch ms in number>
  const email = asciiString.split(':')[0];
  const linkExpirationTime = parseInt(asciiString.split(':')[1], 10);
  if (linkExpirationTime < Date.now() / 1000) {
    navigate('/auth/login');
  }
  const verificationCode = asciiString.split(':')[2];
  const formik = useFormik({
    initialValues: {
      confirmPassword: '',
      newPassword: '',
    },
    validate: (values) => {
      const errors = {};
      const passwordError = passwordValidate(values.newPassword);
      if (passwordError) {
        errors['newPassword'] = passwordError;
      }
      const confirmPasswordError = passwordValidate(values.confirmPassword);
      if (confirmPasswordError) {
        errors['confirmPassword'] = confirmPasswordError;
      } else if (values.confirmPassword !== values.newPassword) {
        errors['confirmPassword'] = 'Passwords do not match';
      }

      return errors;
    },
    onSubmit: (values) => {
      const requestObj = {
        newPassword: values.newPassword,
        linkExpirationTime,
        verificationCode,
        email,
      };
      dispatch(ResetPasswordRequest(requestObj))
        .unwrap()
        .then(() => navigate('/auth/login'))
        .catch((err) => err);
    },
  });

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <InputGroup>
        <Input
          label="New Password"
          placeholder="Enter your new password"
          type="password"
          name={'newPassword'}
          errorMessage={formik.errors.newPassword}
          value={formik.values.newPassword}
          onBlur={formik.handleBlur}
          onValueChange={(e) => {
            formik.setFieldTouched('newPassword', true);
            formik.handleChange(e);
          }}
          errorCondition={
            formik.touched.newPassword && formik.errors.newPassword
          }
        />
        <Input
          label="Confirm Password"
          placeholder="Enter your new password again"
          name={'confirmPassword'}
          type={'password'}
          errorMessage={formik.errors.confirmPassword}
          value={formik.values.confirmPassword}
          onBlur={formik.handleBlur}
          onValueChange={(e) => {
            formik.setFieldTouched('confirmPassword', true);
            formik.handleChange(e);
          }}
          errorCondition={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
        <Button
          type="primary"
          disabled={!formik.isValid}
          data-testid=""
          loading={status === 'loading'}
        >
          Login with new password
        </Button>
        {/* {status === 'failed' ? (
          <Button type="primary" to="/auth/password-recovery">
            Get new code
          </Button>
        ) : (
          
        )} */}
      </InputGroup>
      {status === 'failed' && (
        <ErrorMessage>
          {ResetPasswordResponse.message || ResetPasswordResponse.error}
        </ErrorMessage>
      )}
    </StyledForm>
  );
};
const ResetPassword = () => {
  return (
    <>
      <LoginImage />
      <Wrapper>
        <Container>
          <Header />
          <ResetForm />
        </Container>
      </Wrapper>
    </>
  );
};
export default ResetPassword;
