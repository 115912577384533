import React from 'react';
import { Router, Redirect } from '@gatsbyjs/reach-router';

import { ThemeProvider } from 'styled-components';
import theme from '../styles/colors';

import { routes } from '../navigator/routes';
import Login from '../features/login/Login';
import PasswordRecovery from '../features/login/components/PasswordRecovery';
import ResetPassword from '../features/login/components/ResetPassword';
import RecoveryEmailSent from '../features/login/components/RecoveryEmailSent';
import Signup from '../features/signup/Signup';
import Verify from '../features/signup/Verify';
import ThankYouPage from '../features/signup/components/ThankYouPage';
import AddUser from '../features/signup/components/AddUser';
import UserDetail from '../features/signup/components/UserDetail';

const Auth = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Login path={routes.login.path} />
      <PasswordRecovery path={routes.passwordRecoveryEmailInput.path} />
      <RecoveryEmailSent path={routes.recoveryEmailSent.path} />
      <ResetPassword path={routes.resetPassword.path} />
      <Login path={routes.login.path + '/:pathname'} />
      <Signup path={routes.signup.path} />
      <Verify path={routes.verify.path + '/:code'} />
      <ThankYouPage path={routes.thankYouPage.path} />
      <AddUser path={routes.addUser.path} />
      <UserDetail path={routes.userDetails.path}/>
    </Router>
  </ThemeProvider>
);

export default Auth;
