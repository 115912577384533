import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import styled from 'styled-components';
import Button from '../../../components/Button';
import { Body, Bold, LargeTitle } from '../../../styles/typography';
import LoginImage from './LoginImage';

const StyledHeader = styled.div`
  display: grid;
  gap: 12px;
`;
const Container = styled.div`
  max-width: 480px;
  margin-left: 50vw;
  padding: 32px;
  min-height: -webkit-fill-available;
  display: grid;
  gap: 32px;

  @media screen and (max-width: 800px) {
    margin-left: 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Header = ({ email }) => {
  return (
    <StyledHeader>
      <LargeTitle>Password Recovery</LargeTitle>
      <Body>
        If an account exists for {email}, we've sent a link to reset your
        password. Check your inbox for an email from:{' '}
        <Bold>{'support@mystoriesmatter.com'}</Bold>
      </Body>
    </StyledHeader>
  );
};
const RecoveryEmailSent = ({ location }) => {
  return (
    <>
      <LoginImage />
      <Wrapper>
        <Container>
          <Header email={location?.state?.email} />
          <Button to="/auth/login" type="primary">
            <FaArrowLeft />
            Return to login
          </Button>
        </Container>
      </Wrapper>
    </>
  );
};

export default RecoveryEmailSent;
